.summary-page {
  article.show {
    margin: 2em 0;
    .header-wrap {
      display: flex;
      flex-direction: column-reverse;
      @include media(">=tablet") {
        gap: 1.5em;
        flex-direction: row;
      }
      a {
        display: flex;
        flex-grow: 1;
        background-color: #eee;
        text-decoration: none;
        color: inherit;
        position: relative;
        transition: 150ms ease all;
        &:after {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          content: "";
          display: block;
          background: $gradient_vertical;
          width: 3px;
          height: 100%;
          transition: inherit;
          opacity: 0;
        }
        &:hover {
          // opacity: 0.95;
          filter: brightness(1.15);
          &:after {
            opacity: 1;
          }
        }
        header {
          width: 100%;
          h2 {
            margin: 0;
          }
          h3.subtitle {
            margin: 0;
            font-size: 1.3em;
          }
          hr {
            margin: 1em 0;
            border: none;
            border-bottom: 1px solid black;
          }
          padding: 1.5em;
        }
      }
      .video {
        @include media(">=tablet") {
          width: 30%;
        }
        position: relative;
        .play-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          position: absolute;
          width: 100%;
          font-size: 3em;
          color: white;
          z-index: 1;
          text-shadow: 0 0.05em 0.12em rgba(0, 0, 0, 0.4);
          transition: transform 130ms cubic-bezier(0.165, 0.84, 0.44, 1);
          &:hover {
            transform: scale(1.05);
          }
        }
        &:after {
          content: "";
          display: block;
          padding-bottom: 75%;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .content {
      margin: 0.7em 0 5em;
    }
  }
}
