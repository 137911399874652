section.series {
  .section-title {
    background: $gradient;
    display: inline-block;
    padding: 0.3em 0.7em;
    padding-inline-start: 1.3em;
    color: $white;
    font-size: 1.8em;
    margin: 1em 0;
  }
  & > .inside {
    display: grid;
    grid-gap: 1.3em;
    grid-template-columns: repeat(2, 1fr);
    @include media(">30em") {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media(">50em") {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media(">70em") {
      grid-template-columns: repeat(5, 1fr);
    }
    @include media(">90em") {
      grid-template-columns: repeat(6, 1fr);
    }
    .serie {
      a {
        cursor: pointer;
        transition: 150ms ease all;
        text-decoration: none;
        background-color: #eee;
        display: block;
        color:inherit;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        &:after {
          position: absolute;
          bottom: 0;
          width: 100%;
          content: '';
          display: block;
          background: $gradient;
          height: 3px;
          transition: inherit;
          opacity: 0;
        }
        &:hover {
          // opacity: 0.95;
          filter: brightness(1.15);
          &:after {
            opacity: 1;
          }
        }
        .img-wrap {
          position: relative;
          padding-bottom: 66.66%;
          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .data {
          padding: 0.7em 0.9em 0.8em;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          h3 {
            font-size: 1.3em;
            margin: 0;
          }
          h4 {
            font-size: 1em;
            margin: 0;
            font-weight: 400;
          }
          .show-type {
            text-align: end;
            font-weight: 700;
            margin: auto 0 0;
            padding-top:0.5em;
            font-size: 0.85em;
          }
        }
      }
    }
  }
}
