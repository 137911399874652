.contact-type {
  text-align: center;
}
.contacts {
  h5 {
    text-align: center;
  }
  .contact {
    text-align: center;
    margin-bottom: 0.6em;
  }
}
