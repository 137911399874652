header.header {
  background: $light-gray;
  display: flex;
  position: relative;
  @include media(">desktop") {
    align-items: stretch;
  }
  section.logo {
    justify-content: space-between;
    padding: 1em 1.3em;
    background: $white;
    height: 100%;
    // @include media(">desktop") {
    //   padding: 1.5em 2.2em;
    // }
    h1 {
      margin: 0;
      line-height: 1;
      font-size: 1em;
    }
    img {
      display: block;
      width: 4.5em;
      // @include media(">desktop") {
      //   width: 4em;
      // }
    }
  }
  nav {
    @include media("<=desktop") {
      flex-grow: 1;
    }
    @include media(">tablet") {
      font-size: 1.3em;
    }
    @include media(">desktop") {
      margin-inline-start: auto;
      margin-inline-end: auto;
      display: flex;
      align-items: center;
    }
    ul {
      margin: 0;
      padding: 1.5em;
      display: flex;
      list-style: none;
      flex-flow: column;
      gap: 0.6em;
      @include media(">desktop") {
        gap: 1.3em;
        flex-flow: initial;
        // width: 4em;
      }
      li {
        line-height: 1.1;
        a {
          font-weight: 300;
          cursor: pointer;
          color: inherit;
          white-space: nowrap;
          position: relative;
          text-decoration: none;
          &:hover {
          }
          &.is-active {
            font-weight: 700;
            &:after {
              position: absolute;
              content: "";
              width: 100%;
              top: 100%;
              left: 0;
              background: $gradient;
              height: 3px;
            }
          }
        }
      }
    }
  }
  .search-button {
    margin: 1em;
    padding: 1em;
    // margin-inline-start: auto;
    width: 1.5em;
    height: 1.5em;
    box-sizing: content-box;

    background-size: 60% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.83 31.71'%3E%3Cpath d='M147.86,70.51l-5.73-5.92a14.41,14.41,0,0,0,3.08-8.93,13.88,13.88,0,1,0-13.87,14.26A13.53,13.53,0,0,0,140,66.78l5.67,5.86a1.53,1.53,0,0,0,2.2-2.13ZM120.52,55.66a10.82,10.82,0,1,1,21.63,0A11.38,11.38,0,0,1,139,63.52l0,0-.07.07a10.57,10.57,0,0,1-7.58,3.24A11,11,0,0,1,120.52,55.66Z' transform='translate(-117.46 -41.4)'/%3E%3C/svg%3E");
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .left-side {
    // position: absolute;
    // left: 0;
    // top: 0;
    // height: 100%;
    display: inline-flex;
    .social-icons {
      height: 100%;
      display: flex;
      margin-inline-end: 0.5em;
      @include media("<=tablet") {
        flex-direction: column-reverse;
        padding-block-end: 0.5em;
      }
      @include media(">tablet") {
        align-items: center;
        justify-content: center;
      }
      a {
        display: inline-block;
        padding: 0.15em;
        @include media(">tablet") {
          font-size: 1.2em;
        }
      }
    }
    .name {
      background: $gradient;
      color: $white;
      padding: 0.9em;
      display: flex;
      position: relative;
      height: 100%;
      z-index: 9;
      line-height: 1.2;
      @include media("<=tablet") {
        background: $gradient_vertical;
        > div {
          white-space: nowrap;
          width: 1em;
          padding-inline-start: 1em;
          transform: rotate(-90deg);
          transform-origin: left bottom;
          font-weight: bold;
          height: 0em;
        }
      }
      @include media(">tablet") {
        align-items: center;
        justify-content: center;
        text-align: center;
        .month {
          display: block;
          font-weight: 700;
          padding-top: 0.05em;
        }
        .year {
          display: block;
          font-weight: 400;
          font-size: 1.35em;
          letter-spacing: 0.04em;
        }
      }
    }
  }
}
