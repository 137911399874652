.airing-page {
  overflow: auto;
  .airing-table {
    display: grid;
    margin: 2em 0;
    .row {
      //   grid-template-columns: repeat(6, 1fr);
      grid-template-columns:
        minmax(100px, 4.33fr) minmax(100px, 2.33fr) minmax(100px, 2.33fr)
        minmax(100px, 2.33fr) minmax(100px, 2.33fr) minmax(100px, 2.33fr);

      &:hover {
        background-color: #f9f9f9;
      }
      display: grid;
      > * {
        color: inherit;
        hr {
          border: none;
          border-bottom: 1px solid $border;
        }
        padding: 0.4em 0.6em;
        &:first-child {
          border-right: 1px solid $border;
        }
        border-bottom: 1px solid $border;
        border-left: 1px solid $border;
        // text-align: center;
        .play {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          button.play-button {
            font-size: 2.5em;
            transition: transform 130ms cubic-bezier(0.165, 0.84, 0.44, 1);
            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
      &.header {
        font-weight: 700;
        background: $gradient;
        color: $white;
        > div {
          border-color: white;
        }
      }
    }
  }
}
