:root {
  --swiper-theme-color: #fff !important;
}
section.slider {
  > .inside {
    img {
      width: 100%;
      display: block;
      height: calc(100vh - 6.55em);
      object-fit: cover;
    }
    .release-details {
      position: absolute;
      padding: 20px 35px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
      bottom: 9vh;
      right: 9vh;
      font-size: 18px;
      max-width: 480px;
      opacity:0;
      h3 {
        margin: 0;
      }
      h4 {
        margin: 0;
      }
      hr {
        margin: 0.8em 0;
        border: none;
        border-bottom: 1px solid $white;
      }
      .broadcast-times-description {

      }
    }
  }
  .swiper-slide {
    &.swiper-slide-active {
      .release-details {
        @keyframes up {
          0% {
            transform: translateY(100%);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
        animation: up ease-out 300ms 200ms forwards;
      }
    }
  }
}
